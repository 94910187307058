
<template>
    <div :class="deviceType == 'pc'?'about':'about-m'">
        <div class="banner flex-column">
            <h1>用我们的专业满足您的需求</h1>
            <h3>以数字科技引领全球，用行业服务创造价值</h3>
        </div>
        <div class="module1 flex-column">
            <h1>公司简介</h1>
            <p>社维思是以技术为核心，数据为驱动的企业数字化服务企业。属于成都本土明星企业，经过这些年在行业深耕和沉淀，业务覆盖企业IT软件研发服务、互联网软件运营服务、互联网软件运维服务、互联网品牌建设服务四大服务体系，为企业提供专业化、整合式数字化转型综合解决方案。
                成都社维思网络有限公司，专注于国内“智慧社区商业服务生态圈”建设，正努力成为“智慧社区综合信息服务云平台”及“智慧社区解决方案”的专业的、品牌的供应商。</p>
            <p>
                公司成立于2016年2月，当前注册资本500万，目前入驻于当前世界最大的单体建筑“环球中心”，创业团队大多是国际、国内一流IT企业资深人士组成，具备强硬的智慧商城建设和运营能力、智慧城市规划设计能力、信息资源整合能力、大数据研发及分析能力、移动互联网技术、物联网等定制开发能力等。</p>
        </div>
        <div class="module1 flex-column">
            <h1>发展战略</h1>
            <div class="strategy flex-column">
                <p>
                    社维思将以“专业化、标准化、统一化”为目标，不断优化我们的产品与服务，为企业提供“省 心、放心、贴心、称心、细心、耐心”的“六心”综合数字化外包服务，加速中小型企业发展进程，助力企业数字化转型，做中国综合性数字化解决方案的领航者。
                </p>
                <p>
                    规划未来五年内在成都建立“智慧社区”一院三中心：智慧社区研究院、智慧社区产品开发中心、社区运营中心、社区大数据中心。
                </p>
            </div>
        </div>
        <div class="module1 flex-column">
            <h1>企业文化</h1>
            <div class="culture flex-row">
                <div>
                    <h3>做中国综合性数字化解决方案的领航者。</h3>
                    <p>
                        <strong>企业精神：</strong> 奉献、创新、务实、高效
                    </p>
                    <p>
                        <strong>核心价值观：</strong> 诚信、进取、合作、共赢
                    </p>
                    <p>
                        <strong>服务理念：</strong> 以企业之心为心，处处用心
                    </p>
                    <p>
                        <strong>管理理念：</strong> 以人为本、诚实守信、科技创新、追求卓越
                    </p>
                </div>
                <div class="bg"></div>
            </div>
        </div>
        <div class="module1 flex-column">
            <h1>办公地点</h1>
            <div class="address flex-column">
                <a href="https://map.baidu.com/@11585101.601166163,3555946.7317529344,17z/latlng%3D30.576584%252C104.069606%26title%3D%25E5%2585%25AC%25E5%258F%25B8%25E5%259C%25B0%25E5%259D%2580%26content%3D%25E5%259C%25B0%25E5%259D%2580%25EF%25BC%259A%25E5%259B%259B%25E5%25B7%259D%25E7%259C%2581%25E6%2588%2590%25E9%2583%25BD%25E5%25B8%2582%25E6%25AD%25A6%25E4%25BE%25AF%25E5%258C%25BA%25E7%258E%25AF%25E7%2590%2583%25E4%25B8%25AD%25E5%25BF%2583">
                    <img src="http://api.map.baidu.com/staticimage/v2?ak=Cdh8vef2ybfw8e7vgC8oFe1m9T7Z90gh&mcode=666666&center=104.070734,30.575041&width=1000&height=600&zoom=16" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.about {
    padding: 80px 0;
    .banner {
        width: 100vw;
        min-width: 1000px;
        height: 400px;
        position: relative;
        overflow: hidden;
        background: url('../assets/about_banner.png') center no-repeat;
        // background: red;
        background-position-x: -10px;
        color: rgb(255, 255, 255);
        justify-content: center;
        h1 {
            font-size: 72px;
        }
        h3 {
            font-size: 36px;
        }
    }
    .module1 {
        margin: 120px 0;
        h1 {
            font-size: 42px;
            margin-bottom: 40px;
            font-weight: bolder;
        }
        p {
            width: 1000px;
            text-align: start;
            font-size: 20px;
            letter-spacing: 2px;
            line-height: 30px;
            text-indent: 40px;
        }
        .strategy {
            width: 1400px;
            height: 300px;
            background: url('../assets/v2_qjq8bn.jpg') center no-repeat;
            justify-content: center;
            p {
                color: rgb(255, 255, 255);
                font-size: 20px;
            }
        }
        .culture {
            width: 1400px;
            height: 400px;
            background-color: rgba(242, 242, 247, 100);
            text-align: start;
            div {
                width: 600px;
                height: 300px;
            }
            h3 {
                font-size: 32px;
                margin: 24px 0;
            }
            p {
                font-size: 24px;
                margin: 8px 0;
            }
            .bg {
                background: url('../assets/culture_bg.png') center no-repeat;
            }
        }
        .address {
            img {
                width: 100%;
                height: 600px;
            }
        }
    }
}
.about-m {
    width: 100%;
    padding: 60px 0;
    h1 {
        font-size: 24px;
        margin: 20px 0;
        font-weight: bolder;
    }
    h3 {
        font-size: 18px;
        margin: 8px 0;
    }
    p {
        font-size: 14px;
        text-align: start;
        text-indent: 28px;
        padding: 0 16px;
    }
    .banner {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        background: url('../assets/about_banner.png') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
    }
    .module1 {
        .strategy {
            width: 100%;
            height: 200px;
            background: url('../assets/v2_qjq8bn.jpg') center no-repeat;
            justify-content: center;
            p {
                color: rgb(255, 255, 255);
            }
        }
        .culture {
            width: 100%;
            height: 240px;
            background-color: rgba(242, 242, 247, 100);
            text-align: start;
            div {
                width: 200px;
                height: 200px;
            }
            h3 {
                font-size: 16px;
                margin: 4px 0;
            }
            p {
                margin: 2px 0;
                text-indent: 0;
                padding: 0;
            }
            .bg {
                background: url('../assets/culture_bg.png') center no-repeat;
            }
        }
        .address {
            img {
                width: 100%;
                height: 300px;
            }
        }
    }
}
</style>
